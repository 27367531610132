<template>
  <div
    id="main-wrapper"
    class="inner-pages forex-page indices-page stocks-page"
  >
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li><router-link to="/forex">{{$t('stocksPage.Forex')}}</router-link></li>
            <li><router-link to="/indices">{{$t('stocksPage.Indices')}}</router-link></li>
            <li><router-link to="/commodities">{{$t('stocksPage.Commodities')}}</router-link></li>
            <li class="active">
              <router-link to="/stocks">{{$t('stocksPage.Stocks')}}</router-link>
            </li>
            <li><router-link to="/crypto">{{$t('stocksPage.Cryptocurrencies')}}</router-link></li>
          </ul>
        </div>
      </div>
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{$t('stocksPage.heading')}}</h2>
        <p>{{$t('stocksPage.headingDescription')}}</p>
      </div>
    </div>
    <div class="section strip-details">
      <div class="wrapper2">
        <div class="strip-info wow fadeIn">
          <h3>{{$t('stocksPage.min')}}. <span>{{$t('stocksPage.zeroPointZeroSeventyFive')}}%</span> {{$t('stocksPage.Commission')}}</h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.3s">
          <h3>{{$t('stocksPage.minExecution')}} <span>{{$t('stocksPage.tenMiliSeconds')}}</span></h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.6s">
          <h3>{{$t('stocksPage.min')}}. <span>{{$t('stocksPage.zeroPointZero')}}</span> {{$t('stocksPage.Spread')}}</h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.9s">
          <h3><span>{{$t('stocksPage.hundredPlus')}}</span> {{$t('stocksPage.Stocks')}}</h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="1.2s">
          <h3>{{$t('stocksPage.UpTo')}} <span>{{$t('stocksPage.five')}}x</span> {{$t('stocksPage.Leverage')}}</h3>
        </div>
      </div>
    </div>
    <div class="section forex-content">
      <div class="wrapper">
        <div class="title-holder t-left wow fadeIn">
          <h3><strong>{{$t('stocksPage.StocksContract')}}</strong> {{$t('stocksPage.Specifications')}}</h3>
          <p>
            {{$t('stocksPage.StocksContractSpecifications')}}            
            <a href="mailto:support@aaafx.com">support@aaafx.com</a>
          </p>
        </div>
        <div class="table-holder home-table wow fadeIn">
          <div class="search-holder">
            <input
              type="text"
              name=""
              value=""
              placeholder="Search Instrument"
            />
          </div>
          <table>
            <tr>
              <th>{{$t('stocksPage.Instrument')}}</th>
              <th>{{$t('stocksPage.Bid')}}</th>
              <th>{{$t('stocksPage.Ask')}}</th>
              <th class="">{{$t('stocksPage.Spread')}}</th>
              <th class="">{{$t('stocksPage.ContractSize')}}</th>
              <th class="">{{$t('stocksPage.MinTradeSize')}}</th>
              <th class="">{{$t('stocksPage.TickValue')}}</th>
              <th>{{$t('stocksPage.TradingHours')}}</th>
            </tr>
            <tr
              v-for="(spreads, key, index) in get_currencies"
              :key="key"
              :index="index"
              v-show="index < page * limit && index >= (page - 1) * limit"
            >
              <td>
                <!--  <img src="assets/images/bitcoin-ico.webp" alt="">  -->{{
                  key
                }}
              </td>
              <!-- <td><span class="lev">1-30X</span></td> -->
              <td>
                <span
                  :class="[
                    {
                      't-green': spreads.buy_increase_staus > 0,
                      't-red': spreads.buy_increase_staus > 0,
                    },
                  ]"
                  >{{ spreads.B }}</span
                >
              </td>
              <td>
                <span
                  :class="[
                    {
                      't-green': spreads.sell_increase_staus > 0,
                      't-red': spreads.sell_increase_staus > 0,
                    },
                  ]"
                  >{{ spreads.A }}</span
                >
              </td>
              <td class="">{{ spreads.SPREAD }}</td>
              <td class="">{{ spreads.other.Contract_Size }}</td>
              <td class="">{{ spreads.other.Min_Trade_Size }}</td>
              <td class="">{{ spreads.other.Tick_Value }}</td>
              <td class="">{{$t('stocksPage.sevenAM')}} - {{$t('stocksPage.twelvePM')}}</td>
            </tr>
          </table>
          <div class="clearfix links">
            <!-- <a href="#" class="t-right see-link f-right"> Next</a> -->
            <pagination
              :totalRecords="Object.keys(this.get_currencies).length"
              :limit="limit"
              :pageNo="page"
            />
          </div>
        </div>
        <div class="section">
          <div class="title-holder t-left">
            <p>{{$t('stocksPage.Treasury_products_description')}}</p>
          </div>
          <div class="table-holder expiration-table">
            <div class="title-holder t-center">
              <h6>{{$t('stocksPage.ExpirationDateCaps')}}</h6>
            </div>
            <table>
              <tr>
                <th>{{$t('stocksPage.Bund')}}</th>
                <th>{{$t('stocksPage.CORNF')}}</th>
                <th>{{$t('stocksPage.WHEATF')}}</th>
                <th>{{$t('stocksPage.SOYF')}}</th>
              </tr>
              <tr>
                <td>{{$t('stocksPage.seventhDecemberTwentyTwentyOne')}}</td>
                <td>{{$t('stocksPage.twentysixNovemberTwentyOne')}}</td>
                <td>{{$t('stocksPage.twentysixNovemberTwentyOne')}}</td>
                <td>{{$t('stocksPage.twentysevenNovemberTwentyOne')}}</td>
              </tr>
              <tr>
                <td>{{$t('stocksPage.seventhDecemberTwentyTwentyOne')}}</td>
                <td>{{$t('stocksPage.twentyFourFebruaryTwentyTwo')}}</td>
                <td>{{$t('stocksPage.twentyFourFebruaryTwentyTwo')}}</td>
                <td>{{$t('stocksPage.twentyNineDecemberTwentyOne')}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="section-small">
          <div class="accordion-holder t-left">
            <h3 v-on:click="Stocks ^= true" :class="Stocks?'active':''">{{$t('stocksPage.WhatisStocksCFD')}}</h3>
            <div class="content" v-show="Stocks">
              <p>{{$t('stocksPage.WhatisStocksCFDDesc')}} </p>
            </div>
            <h3 v-on:click="work ^= true" :class="work?'active':''">{{$t('stocksPage.How_does_it_work')}}</h3>
            <div class="content" v-show="work">
              <p>{{$t('stocksPage.How_does_it_work_desc')}}</p>
            </div>
          </div>
        </div>

        <div class="section account-types">
          <div class="title-holder t-left wow fadeIn">
            <h3>{{$t('stocksPage.Account')}} <strong>{{$t('stocksPage.Types')}}</strong></h3>
          </div>
          <div class="table-holder home-table acct-type wow fadeIn">
            <table>
              <tr>
                <th>&nbsp;</th>
                <th>{{$t('stocksPage.ECN')}}</th>
                <th>{{$t('stocksPage.ECN')}} <span>{{$t('stocksPage.plus')}}</span></th>
                <th>{{$t('stocksPage.ECN')}} <span>{{$t('stocksPage.zulu')}}</span></th>
              </tr>
              <tr>
                <td>{{$t('stocksPage.DepositMin')}}</td>
                <td>${{$t('stocksPage.hundred')}}</td>
                <td>${{$t('stocksPage.tenThousand')}}</td>
                <td>${{$t('stocksPage.hundred')}}</td>
              </tr>
              <tr>
                <td>{{$t('stocksPage.CommissionPerLakh')}}</td>
                <td>{{$t('stocksPage.zeroPointZeroNinetyThree')}}%</td>
                <td>{{$t('stocksPage.zeroPointZeroSeventyFive')}}%</td>
                <td>{{$t('stocksPage.one')}}%</td>
              </tr>
              <tr>
                <td>{{$t('stocksPage.SpreadMin')}}</td>
                <td>{{$t('stocksPage.zeroPointZero')}}</td>
                <td>{{$t('stocksPage.zeroPointZero')}}</td>
                <td>{{$t('stocksPage.zeroPointZero')}}</td>
              </tr>
            </table>
            <div class="clearfix links">
              <router-link to="/account-types" target="_blank" class="t-right see-link f-right">{{$t('stocksPage.CompareAccountTypes')}}</router-link>
            </div>
          </div>
        </div>

        <div class="section trading-plat">
          <div class="info-holder wow fadeInLeft">
            <div class="title-holder">
              <h3>
                {{$t('stocksPage.OurOnline')}} <strong>{{$t('stocksPage.Trading')}} <br />{{$t('stocksPage.Platforms')}}</strong>
              </h3>
            </div>
            <div class="link-holder col">
              <a href="#">
                <img src="assets/images/plat-ico1.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> {{$t('stocksPage.Desktop')}}</a
              >
            </div>
            <div class="link-holder col">
              <a href="#">
                <img src="assets/images/plat-ico2.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> {{$t('stocksPage.WebTrader')}}</a
              >
            </div>
            <div class="link-holder col">
              <a href="#">
                <img src="assets/images/plat-ico3.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> {{$t('stocksPage.Android')}}</a
              >
            </div>
            <div class="link-holder col">
              <a href="#">
                <img src="assets/images/plat-ico4.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> {{$t('stocksPage.iOS')}}</a
              >
            </div>
            <div class="btn-holder">
              <router-link to="/compare-platform" class="btn btn-bluey">{{$t('stocksPage.LeranMore')}}</router-link>
            </div>
          </div>
          <div class="links-holder wow fadeInRight">
            <div class="link-holder col">
              <img src="assets/images/plat3.webp" alt="AAAFx" title="AAAFx" width="237" height="100" class="fluid-img" />
            </div>
          </div>
        </div>

        <div class="section nega-sec">
          <div class="content-holder wow fadeInLeft">
            <div class="title-holder">
              <h3><strong>{{$t('stocksPage.NegativeBalance')}}</strong> {{$t('stocksPage.Protection')}}</h3>
              <p class="sub">
                {{$t('stocksPage.ReliableProtectionHeading')}}
              </p>
              <br />
              <p>{{$t('stocksPage.ReliableProtectionDescription')}}</p>
              <div class="btn-holder">
                <a :href="siteurl2" target = "_blank" class="btn btn-yellow" >{{$t('stocksPage.OpenLiveAccount')}}</a>
                <a  :href="siteurl2"  target = "_blank"  class="btn btn-blue"  >{{$t('stocksPage.OpenDemoAccount')}}</a>
              </div>
            </div>
          </div>
          <div class="img-holder t-center wow fadeInRight">
            <img src="assets/images/nega-img.webp" alt="AAAFx" title="AAAFx" width="579" height="433" class="fluid-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Const } from "../plugins/vue-provider-cache/const.mod";
import pagination from "@/components/shared/pagination.vue";
export default {
  name: "forex",
  data() {
    return {
      page: 1,
      limit: 8,
      Stocks: true,
      work: false,
      siteurl : process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2 : process.env.VUE_APP_CLIENT_REGISTER_URL, 
    };
  },
  components: {
    pagination: pagination,
  },
  computed: {
    get_currencies() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getStocksAll();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
  },
};
</script>
